<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-form
      ref="member-form"
      @submit.prevent="onSubmit()">
      <v-row class="products-row elevation-3 rounded">
        <v-col
          cols="12"
          class="font-weight-bold"
          style="font-size: 20px;">
          สร้างสมาชิก
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="tel-column">
          <vue-tel-input-vuetify
            v-model="form.tel"
            label="เบอร์โทรศัพท์"
            default-country="th"
            :rules="textBoxRules"
            dense
            outlined
            hide-details
            @country-changed="countryChange($event)" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model.trim="form.email"
            label="Email"
            :rules="emailBoxRules"
            dense
            hide-details
            outlined />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model.trim="form.firstName"
            label="ชื่อ"
            :rules="textBoxRules"
            dense
            hide-details
            outlined />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model.trim="form.lastName"
            label="นามสกุล"
            :rules="textBoxRules"
            dense
            hide-details
            outlined />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-menu
            ref="menu"
            v-model="birthDateMenu"
            :close-on-content-click="false"
            :return-value.sync="form.birthDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDateCompute"
                label="วันเกิด"
                :rules="textBoxRules"
                dense
                hide-details
                outlined
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on" />
            </template>
            <v-date-picker
              v-model="form.birthDate"
              :max="$dayjs(new Date()).format()"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="birthDateMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(form.birthDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-select
            v-model="form.gender"
            :items="genders"
            label="เพศ"
            :rules="textBoxRules"
            dense
            hide-details
            outlined />
        </v-col>
        <v-col cols="12">
          <v-radio-group
            v-model="form.type"
            label="ประเภทสมาชิก"
            row>
            <v-radio
              label="VVIP"
              value="vvip" />
            <v-radio
              label="VIP"
              value="vip" />
            <v-radio
              label="Member"
              value="normal" />
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          class="text-center">
          <v-btn
            color="primary"
            @click="onSubmit()">
            เพิ่มสมาชิก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'

const MemberService = new MemberProvider()

export default {
  data () {
    return {
      textBoxRules: [
        (v) => !!v || 'Field is require!'
      ],
      emailBoxRules: [
        (v) => !!v || 'Field is require!',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      birthDateMenu: false,
      genders: [
        { text: 'หญิง', value: 'female' },
        { text: 'ชาย', value: 'male' },
        { text: 'ไม่ระบุ', value: 'N/A' }
      ],
      form: {
        firstName: null,
        lastName: null,
        birthDate: null,
        email: null,
        tel: null,
        telInfo: null,
        type: 'normal',
        gender: null,
        createdIn: 'manual_storefront'
      }
    }
  },
  computed: {
    birthDateCompute () {
      return this.form.birthDate ? this.$dayjs(this.form.birthDate).format('DD MMM YYYY') : null
    }
  },
  watch: {
    'form.tel': {
      handler (val) {
        this.form.tel = this.setTel(val, this.form.telInfo)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    countryChange (telInfo) {
      this.form.telInfo = telInfo
    },
    setTelInfo (tel, telInfo) {
      this.form.tel = this.setTel(tel, telInfo)
      this.form.telInfo = telInfo
    },
    setTel (tel, telInfo) {
      const tmpTel = tel.replace(/\s+/g, '')

      if (telInfo.dialCode === '66' && tmpTel.length === 10 && tmpTel[0] === '0') {
        return `+${telInfo.dialCode}${tmpTel.slice(1)}`
      }

      return tmpTel
    },
    async onSubmit () {
      const validated = await this.$refs['member-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'CREATING MEMBER...'
        })

        await MemberService.createMember({
          ...this.form,
          tel: this.form.tel.trim().replaceAll(' ', '').replaceAll('-', '')
        })

        this.setSnackbar({
          value: true,
          message: 'สร้างสมาชิกสำเร็จ',
          type: 'success'
        })

        this.$router.push({ name: 'MemberManagement' })
      } catch (error) {
        console.error('onSubmit', error)

        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style>
.tel-column .country-code .v-select .v-input__slot {
  margin-bottom: 0 !important;
}
.tel-column .country-code .v-select .v-text-field__details {
  min-height: 0 !important;
  margin-bottom: 0 !important;
  height: 0 !important;
}
.tel-column .vue-tel-input-vuetify {
  gap: 8px;
}
</style>
